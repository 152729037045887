<template>
  <div class="loader-container">
    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
  </div>
</template>
<script>
export default {
  name: 'loader',
};
</script>
<style lang="scss" scoped>
.loader-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
